<template>
  <v-row>
    <v-col cols="6">
      <v-card>
        <v-card-title>
          Application Configuration
        </v-card-title>
        <v-card-text>
          <v-treeview :items="hierarchyItems">
            <template v-slot:prepend="{ item, open }">
              <v-icon v-if="item.father_id == ''">
                {{ open ? "mdi-folder-open" : "mdi-folder" }}
              </v-icon>
              <v-icon v-else-if="item.children.length > 0">
                {{ open ? "mdi-folder-open" : "mdi-folder" }}
              </v-icon>
              <v-icon
                v-else-if="
                  item.children.length == 0 &&
                    item.external_link != undefined &&
                    item.external_link != '' &&
                    item.father_id != ''
                "
                :title="item.external_link"
              >
                mdi-file-link
              </v-icon>
              <v-icon
                v-else-if="
                  item.children.length == 0 &&
                    item.path_s3_bucket != undefined &&
                    item.path_s3_bucket != '' &&
                    item.father_id != ''
                "
              >
                mdi-file-pdf
              </v-icon>
              <v-icon v-else>
                mdi-file
              </v-icon>
            </template>
            <template v-slot:label="{ item }">
              <v-row>
                <v-col cols="6">
                  <span>
                    {{ item.node_name }}
                  </span>
                </v-col>
                <v-col cols="6">
                  <span>
                    {{ item.node_description }}
                  </span>
                </v-col>
              </v-row>
            </template>
          </v-treeview>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6">
      <service-treeview
        :route-type="routeType"
        :is-translating="services.isTranslating"
        :is-creating-profiling="true"
        :selected-language="selectedLanguageText"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    ServiceTreeview: () => import('@/components/services/ServiceTreeview')
  },
  props: {
    selectedLanguageText: {
      type: String,
      default: null
    },
    routeType: {
      type: String,
      default: null
    },
    hierarchyItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      overlay: false
    };
  },
  computed: {
    ...mapState(['services', 'file_transfer']),
    splitBucketS3Path() {
      const res = this.services.storeServiceForm.manual_s3_path.split('/');

      return res[1];
    }
  },
  watch: {
    $route() {}
  },
  async created() {
    this.routeType = this.$route.params.type;

    if (!this.services.isTranslating && this.services.service_id === '') {
      this.$router.go(-1);
    }
  },
  methods: {
    ...mapActions([
      'storeGetUrlServiceConfiguration',
      'createUrlServiceTranslationAction',
      'createTranslationServiceAction',
      'createDocumentalServiceTranslationAction',
      'storeTranslationServices',
      'storeGetTranslationNodes',
      'editTranslationNodesAction'
    ])
  }
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>

<style scoped>
.file-input-width {
  width: 600px;
}
</style>
